import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-sparkles-svg',
	imports: [NgClass],
	templateUrl: './sparkles-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SparklesSvgComponent {
	@Input() customStyles = '';
}
