import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'uc-clipboard-svg',
	imports: [NgClass],
	templateUrl: './clipboard-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClipboardSvgComponent {
	customStyles = input('');
}
