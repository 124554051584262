import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-euro-svg',
	imports: [CommonModule],
	templateUrl: './euro-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EuroSvgComponent {
	@Input() customStyles = 'h-8 w-8 text-gray-500';
}
