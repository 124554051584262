import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	selector: 'uc-linkedin-footer-svg',
	imports: [NgClass],
	templateUrl: './linkedin-footer-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedinFooterSvgComponent {
	customStyles = input('');
}
