import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'uc-pencil-square-svg',
	imports: [NgClass],
	templateUrl: './pencil-square-svg.component.html',
})
export class PencilSquareSvgComponent {
	@Input() customStyles = '';
}
