import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-users-solid-svg',
	imports: [NgClass],
	templateUrl: './users-solid-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersSolidSvgComponent {
	@Input() customStyles = '';
}
