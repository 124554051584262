import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-search-svg',
	imports: [CommonModule],
	templateUrl: './search-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchSvgComponent {
	@Input() customStyles = '';
}
