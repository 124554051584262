import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-button-left-arrow2-svg',
	imports: [],
	templateUrl: './button-left-arrow2-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonLeftArrow2SvgComponent {
	@Input() customStyles = '';
}
