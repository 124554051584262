import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'uc-arrow-left-v2-svg',
	imports: [NgClass],
	templateUrl: './arrow-left-v2-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowLeftV2SvgComponent {
	customStyles = 'text-gray-500';
}
