import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-linkedin-no-background-svg',
	imports: [NgClass],
	templateUrl: './linkedin-no-background-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedinNoBackgroundSvgComponent {
	@Input() customStyles = '';
}
