import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	selector: 'uc-open-book-svg',
	templateUrl: './open-book-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
})
export class OpenBookSvgComponent {
	customStyles = input('');
	strokeWidth = input('3');
}
