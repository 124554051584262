import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'uc-information-circle-svg',
	imports: [NgClass],
	templateUrl: './information-circle-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationCircleSvgComponent {
	customStyles = input<string>();
}
