import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-filter-desktop-svg',
	imports: [CommonModule],
	templateUrl: './filter-desktop-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDesktopSvgComponent {
	@Input() customStyles = '';
}
