<svg
	class="w-5 h-5"
	[ngClass]="customStyles()"
	viewBox="0 0 18 14"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M1 1H17M1 7H17M1 13H17"
		stroke="#6B7280"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
