import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	selector: 'uc-exclamation-mark-triangular-svg',
	imports: [NgClass],
	templateUrl: './exclamation-mark-triangular-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExclamationMarkTriangularSvgComponent {
	customStyles = input('');
}
