<svg
	class="w-4 h-4"
	[ngClass]="customStyles()"
	viewBox="0 0 14 14"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_8_154)">
		<path
			d="M11.9908 0.7H9.65366C9.5295 0.487871 9.35071 0.311529 9.13522 0.188666C8.91973 0.0658023 8.67512 0.000737761 8.42594 0H5.57408C5.3249 0.000737761 5.08029 0.0658023 4.8648 0.188666C4.64931 0.311529 4.47052 0.487871 4.34636 0.7H2.00927C1.63109 0.7 1.2684 0.847499 1.00099 1.11005C0.733574 1.3726 0.583344 1.7287 0.583344 2.1V12.6C0.583344 12.9713 0.733574 13.3274 1.00099 13.5899C1.2684 13.8525 1.63109 14 2.00927 14H11.9908C12.3689 14 12.7316 13.8525 12.999 13.5899C13.2664 13.3274 13.4167 12.9713 13.4167 12.6V2.1C13.4167 1.7287 13.2664 1.3726 12.999 1.11005C12.7316 0.847499 12.3689 0.7 11.9908 0.7ZM8.42594 1.4V2.8H5.57408V1.4H8.42594ZM11.9908 12.6H2.00927V2.1H4.14816V2.8C3.95907 2.8 3.77772 2.87375 3.64402 3.00503C3.51031 3.1363 3.4352 3.31435 3.4352 3.5C3.4352 3.68565 3.51031 3.8637 3.64402 3.99497C3.77772 4.12625 3.95907 4.2 4.14816 4.2H9.85186C10.041 4.2 10.2223 4.12625 10.356 3.99497C10.4897 3.8637 10.5648 3.68565 10.5648 3.5C10.5648 3.31435 10.4897 3.1363 10.356 3.00503C10.2223 2.87375 10.041 2.8 9.85186 2.8V2.1H11.9908V12.6Z"
			fill="currentColor"
		/>
		<path
			d="M6.30416 10.1003C6.11771 10.1014 5.93834 10.0302 5.80508 9.9022L4.36205 8.5274C4.29482 8.46305 4.24117 8.38633 4.20414 8.30163C4.16711 8.21692 4.14744 8.12588 4.14625 8.03371C4.14506 7.94154 4.16237 7.85004 4.19719 7.76444C4.23202 7.67883 4.28367 7.6008 4.34921 7.5348C4.41475 7.4688 4.49289 7.41612 4.57917 7.37976C4.66544 7.34341 4.75817 7.3241 4.85205 7.32293C4.94593 7.32176 5.03912 7.33875 5.12631 7.37294C5.2135 7.40713 5.29297 7.45785 5.3602 7.5222L6.30701 8.4245L8.64553 6.1978C8.71206 6.1308 8.79159 6.07758 8.87943 6.04128C8.96726 6.00498 9.06162 5.98634 9.15692 5.98646C9.25222 5.98658 9.34653 6.00545 9.43428 6.04197C9.52202 6.07849 9.60142 6.13191 9.66777 6.19907C9.73413 6.26623 9.7861 6.34577 9.8206 6.43299C9.85511 6.52021 9.87146 6.61333 9.86868 6.70686C9.8659 6.80039 9.84406 6.89242 9.80443 6.97752C9.7648 7.06262 9.7082 7.13905 9.63797 7.2023L6.80395 9.9022C6.67017 10.0299 6.49074 10.101 6.30416 10.1003Z"
			fill="currentColor"
		/>
	</g>
	<defs>
		<clipPath id="clip0_8_154">
			<rect class="w-full h-full" fill="white" />
		</clipPath>
	</defs>
</svg>
