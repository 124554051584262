import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-multi-users-svg',
	imports: [CommonModule],
	templateUrl: './multi-users-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiUsersSvgComponent {
	@Input() customStyles = '';
}
