import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'uc-house-solid-svg',
	imports: [NgClass],
	templateUrl: './house-solid-svg.component.html',
})
export class HouseSolidSvgComponent {
	@Input() customStyles = '';
}
