import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-selector-svg',
	imports: [NgClass],
	templateUrl: './selector-svg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectorSvgComponent {
	@Input() customStyles = '';
}
